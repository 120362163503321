<template>
     <v-row>
          <v-col cols="12">
              <template>
                    <v-simple-table dense>
                    <template v-slot:default>
                         <thead>
                         </thead>
                         <tbody>
                         <tr
                              v-for="(item, key,i) in table"
                              :key="key+i"
                         >
                              <template v-if="isString(item) || item == null">
                                   <th>{{ $t('attributes.'+key+'.label') }}</th>
                                   <template v-if="isDate(key)">
                                        <td v-if="item">{{ [ item ] | moment("dddd, D MMMM YYYY") }}</td>
                                        <td v-else>null</td>
                                   </template>
                                   <template v-else-if="isDateTime(key)">
                                        <td v-if="item">{{ [ item ] | moment("dddd, D MMMM YYYY HH:mm") }}</td>
                                        <td v-else>null</td>
                                   </template>
                                   <template v-else>
                                       <td>{{ (item) ? item : "null" }}</td>
                                   </template>
                              </template>

                              <template v-else-if="isBoolean(item) || item == null">
                              <th>{{ $t('attributes.'+key+'.label') }}</th>
                              <td>
                                   <v-icon color="green accent-4" v-if="item">mdi-check-circle</v-icon>
                                   <v-icon color="red accent-4" v-else>mdi-cancel</v-icon>
                              </td>
                              </template>
                              <template v-else-if="isNumber(item) || item == null">
                                   <th>{{ $t('attributes.'+key+'.label') }}</th>
                                   <td>{{ item }}</td>
                              </template>
                              <template v-else-if="key == 'valid_levels'">
                                   <th>{{ $t('attributes.'+key+'.label') }}</th>
                                   <td> 
                                        <v-chip  class="ma-2" outlined pill size="small" v-for="val in item" :key="val" :color="val">{{val}}</v-chip>
                                   </td>
                              </template>
                              <template v-else-if="isArray(item)">
                                   <td colspan="2" class="pa-2">
                                        <v-expansion-panels inset elevation="0">
                                        <v-expansion-panel>
                                             <v-expansion-panel-header>
                                             <b>{{ $t('attributes.'+key+'.label') }}</b>
                                             </v-expansion-panel-header>
                                             <v-expansion-panel-content v-for="(obj,n) in item" :key="n">
                                                  <simple-table :table="createObj({'tokey':obj},'tokey',key)" ></simple-table>     
                                             </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        </v-expansion-panels>
                                   </td>
                              </template>
                              <template v-else-if="isObject(item)">
                                   <td colspan="2" class="pa-2">
                                        <v-expansion-panels inset elevation="0">
                                        <v-expansion-panel>
                                             <v-expansion-panel-header>
                                             <b>{{ $t('attributes.'+key+'.label') }}</b>
                                             </v-expansion-panel-header>
                                             <v-expansion-panel-content>
                                                  <simple-table :table="item" ></simple-table>
                                             </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        </v-expansion-panels>
                                   </td>
                              </template>
                         </tr>
                         </tbody>
                    </template>
                    </v-simple-table>
               </template>
          </v-col>
     </v-row>
</template>

<script>
  export default {
     name:"SimpleTable",
     props:{
          table: {
               type: [Object, Array],
               required: true,
          }
     },
     data:()=>({
          datesKey: ['date_of_birth','start','end'],
          datesTimeKey: ['created_at','updated_at','email_verified_at','phone_verified_at','issued_on','expired_at']
     }),
     methods:{
          isString(val){
               return typeof val === 'string' || val instanceof String
          },
          isObject(val){
               return (!!val) && (val.constructor === Object);
          },
          isArray(val){
               return (!!val) && (val.constructor === Array)
          },
          isBoolean(val){
               return typeof val === 'boolean' || val instanceof Boolean
          },
          isNumber(val){
               return typeof val === 'number' || val instanceof Number
          },
          isDate(key){
               return this.datesKey.includes(key)
          },
          isDateTime(key){
               return this.datesTimeKey.includes(key)
          },
          createObj(object, key, newKey)  {

               const clonedObj = Object.assign({}, object);
               const targetKey = clonedObj[key];

               delete clonedObj[key];

               clonedObj[newKey] = targetKey;

               return clonedObj;

          }
     }
  }
</script>